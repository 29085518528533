<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Puppy</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="puppy-form">
          <v-text-field
            label="Puppy ID"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>

          <v-select
            label="Breed"
            v-model="fields.breed_id"
            :items="breeds"
            item-text="name"
            item-value="id"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('breed_id')"
            :error-messages="errors['breed_id']"
          ></v-select>

          <v-select
            label="Gender"
            v-model="fields.gender"
            :items="genders"
            item-text="name"
            item-value="value"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('gender')"
            :error-messages="errors['gender']"
          ></v-select>

          <v-text-field
            label="Dob"
            v-model="fields.dob"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('dob')"
            :error-messages="errors['dob']"
          ></v-text-field>

          <v-text-field
            label="Time of Birth"
            v-model="fields.time_of_birth"
            type="time"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('time_of_birth')"
            :error-messages="errors['time_of_birth']"
          ></v-text-field>

          <v-text-field
            label="Weight (kg)"
            type="text"
            v-model="fields.weight"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('weight')"
            :error-messages="errors['weight']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="puppy-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    litter: {
      required: true,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
  },

  data() {
    return {
      fields: {
        name: null,
        gender: null,
        dob: null,
        time_of_birth: null,
        status: "puppy",
        location: "lhl",
        weight: null,
      },
      genders: [
        { name: "Female", value: "F" },
        { name: "Male", value: "M" },
      ],

      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  computed: {
    breeds() {
      return this.$store.state.lhl.breeds["breeds"];
    },
    australian_labradoodle() {
      let breed = this.breeds.filter((breed) => {
        return breed.name == "Australian Labradoodle";
      });

      return breed[0].id;
    },
  },

  methods: {
    openForm: function (litter = null) {
      if (litter !== null) {
        this.fields.litter = litter;
        this.fields.name = litter.name;
        this.fields.gender = litter.gender;
        this.fields.dob = litter.dob;
        this.fields.time_of_birth = litter.time_of_birth;
        this.fields.weight = litter.weight;
        this.fields.breed_id = litter.breed_id;
        this.isEditing = true;
      }

      this.fields.breed_id = this.australian_labradoodle;

      if (this.litter.puppies.length > 0) {
        this.fields.dob = this.litter.dob;
      } else {
        this.fields.dob = new Date().toISOString().slice(0, 10);
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        litterId: this.$route.params.litterId,
        fields: this.fields,
      };

      this.$store
        .dispatch("lhl/litters/savePuppy", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;

      this.fields.name = null;
      this.fields.gender = null;
      this.fields.dob = null;
      this.fields.weight = null;
      this.fields.time_of_birth = null;
      this.fields.status = "puppy";
      this.fields.location = "lhl";
      this.fields.weight = null;
    },
  },
};
</script>
